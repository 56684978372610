<template>
    <div class="container-fluid  timeline-page">
         <b-row >
            <b-col lg="6" >
               <card class=" card-block card-stretch card-height">
                   <template v-slot:headerTitle>
                        <h4 class="card-title">Primary Color Timeline</h4>
                   </template>
                   <template v-slot:body>
                     <div class="iq-timeline2 m-0 d-flex align-items-center justify-content-between position-relative">
                        <ul class="list-inline p-0 m-0">
                           <li>
                              <div class="content-date bg-primary"> <span class="date">16</span>
                                 <span class="month">JAN</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Login</h6>
                                 <small class="float-right mt-1 text-primary">20 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-primary"> <span class="date">25</span>
                                 <span class="month">FEB</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Scheduled Maintenance</h6>
                                 <small class="float-right mt-1 text-primary">15 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-primary"> <span class="date">15</span>
                                 <span class="month">MAR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Call</h6>
                                 <small class="float-right mt-1 text-primary">10 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="mb-0">
                              <div class="content-date bg-primary"> <span class="date">20</span>
                                 <span class="month">APR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Mega event</h6>
                                 <small class="float-right mt-1 text-primary">05 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p class="mb-0">Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available.</p>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                   </template>
               </card>
            </b-col>
            <b-col lg="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Success Color Timeline</h4>
                  </template>
                    <template v-slot:body>
                     <div class="iq-timeline2 m-0 d-flex align-items-center justify-content-between position-relative">
                        <ul class="list-inline p-0 m-0">
                           <li>
                              <div class="content-date bg-success"> <span class="date">16</span>
                                 <span class="month">JAN</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Login</h6>
                                 <small class="float-right mt-1 text-success">20 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-success"> <span class="date">25</span>
                                 <span class="month">FEB</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Scheduled Maintenance</h6>
                                 <small class="float-right mt-1 text-success">15 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-success"> <span class="date">15</span>
                                 <span class="month">MAR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Call</h6>
                                 <small class="float-right mt-1 text-success">10 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="mb-0">
                              <div class="content-date bg-success"> <span class="date">20</span>
                                 <span class="month">APR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Mega event</h6>
                                 <small class="float-right mt-1 text-success">05 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p class="mb-0">Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available.</p>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                    </template>
               </card>
            </b-col>
            <b-col lg="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Danger Color Timeline</h4>
                  </template>
                  <template v-slot:body>
                     <div class="iq-timeline2 m-0 d-flex align-items-center justify-content-between position-relative">
                        <ul class="list-inline p-0 m-0">
                           <li>
                              <div class="content-date bg-danger"> <span class="date">16</span>
                                 <span class="month">JAN</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Login</h6>
                                 <small class="float-right mt-1 text-danger">20 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-danger"> <span class="date">25</span>
                                 <span class="month">FEB</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Scheduled Maintenance</h6>
                                 <small class="float-right mt-1 text-danger">15 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-danger"> <span class="date">15</span>
                                 <span class="month">MAR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Call</h6>
                                 <small class="float-right mt-1 text-danger">10 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="mb-0">
                              <div class="content-date bg-danger"> <span class="date">20</span>
                                 <span class="month">APR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Mega event</h6>
                                 <small class="float-right mt-1 text-danger">05 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p class="mb-0">Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available.</p>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                   </template>
               </card>
            </b-col>
            <b-col lg="6" >
               <card class=" card-block card-stretch card-height">
                 <template v-slot:headerTitle>
                        <h4 class="card-title">Info Color Timeline</h4>
                     </template>
                  <template v-slot:body>
                     <div class="iq-timeline2 m-0 d-flex align-items-center justify-content-between position-relative">
                        <ul class="list-inline p-0 m-0">
                           <li>
                              <div class="content-date bg-info"> <span class="date">16</span>
                                 <span class="month">JAN</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Login</h6>
                                 <small class="float-right mt-1 text-info">20 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-info"> <span class="date">25</span>
                                 <span class="month">FEB</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Scheduled Maintenance</h6>
                                 <small class="float-right mt-1 text-info">15 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div class="content-date bg-info"> <span class="date">15</span>
                                 <span class="month">MAR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Client Call</h6>
                                 <small class="float-right mt-1 text-info">10 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="mb-0">
                              <div class="content-date bg-info"> <span class="date">20</span>
                                 <span class="month">APR</span>
                              </div>
                              <div class="content ml-3">
                                 <h6 class="d-inline-block mb-2">Mega event</h6>
                                 <small class="float-right mt-1 text-info">05 Min Ago</small>
                                 <div class="d-inline-block w-100">
                                    <p class="mb-0">Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available.</p>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </template>
               </card>
            </b-col>
         </b-row>
      </div>
</template>
<script>
export default {
   name:'Timeline4'
}
</script>